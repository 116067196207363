import React, { FC } from 'react'

import { LoadingSpinner } from '@juullabs/react-components'

import { TranslatedMessage } from 'components/common/TranslatedMessage'

import styles from './LoadingSplash.module.scss'

type Props = {
    loadingText?: React.ReactNode | string
}

export const LoadingSplash: FC<Props> = ({ loadingText }) => (
  <div className={styles.container}>
    <LoadingSpinner
      loadingText={loadingText || <TranslatedMessage id='loading' />}
    />
  </div>
)
