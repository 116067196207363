import { DeploymentConfig } from '@viewlio/types/src'
import { GetStaticProps } from 'next'

import { Geofence } from 'components/geofence'
import { getCommonStaticProps } from 'utils/dataFetching/getCommonStaticProps'

export const deploymentConfig: DeploymentConfig = {
  attributes: ['geofence'],
  environments: {
    '*': {
      'juul-uk': true,
    },
  },
}

export const getStaticProps: GetStaticProps = async ({ locale }) =>
  getCommonStaticProps({
    deploymentConfig,
    locale,
    metaEntryLookup: 'geofence',
  }, async () => ({
    skipAgeVerification: true,
    skipPhoneVerification: true,
  }))

export default Geofence
