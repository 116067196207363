import React from 'react'

import { viewlioConfig } from '@viewlio/config/src/viewlioConfig'
import Button from '@viewlio/juulio-bridge/src/common/components/Button'
import { useRouter } from 'next/router'

import { LoadingSplash } from 'components/common/LoadingSplash'
import { LocalizedLink } from 'components/common/LocalizedLink'
import { TranslatedMessage } from 'components/common/TranslatedMessage'
import { useGlobalStore } from 'stores'

import styles from './Geofence.module.scss'

export const Geofence: React.FC = () => {
  const {
    query: {
      force_country_code,
    },
  } = useRouter()

  const {
    shopper: {
      geoLocation,
      meta: { loaded },
      suggestedStore,
    },
    juulioStore: {
      code,
      preferences: {
        forceGeofencing,
      },
    },
  } = useGlobalStore()

  if (!loaded) {
    return (
      <LoadingSplash />
    )
  }

  const currentStore = viewlioConfig.stores[code]
  const userCountry = force_country_code || geoLocation?.countryCode

  const userIsNonLocal = userCountry !== currentStore.countryCode
  const forcedGeofencingRequired = forceGeofencing && userIsNonLocal

  const uiVariation = suggestedStore?.countryIso ? 'suggestedStore' : 'noSuggestedStore'

  if (!forcedGeofencingRequired) {
    window.location.assign('/')
  }

  return (
    <div className={styles.container}>
      <h2 className='heading--medium'>
        <TranslatedMessage
          id='country_selector.global.restrict_foreign_traffic'
          values={{ country: currentStore.label }}
        />
      </h2>
      <p className={styles.p}>
        {uiVariation === 'suggestedStore'
          ? <TranslatedMessage id='country_selector.global.force_geofencing.body' />
          : <TranslatedMessage id='country_selector.global.body' />
        }
      </p>
      {uiVariation === 'suggestedStore'
        ? (
          <LocalizedLink
            className='country-selector__item'
            href={suggestedStore.fullUrl}
          >
            <img
              className={`${styles.flag} country-selector__flag`}
              data-testid='geofence-flag'
              src={suggestedStore.flagUrl}
            />
          </LocalizedLink>
        )
        : (
          <div className='CountrySelectorGlobal__actions'>
            <Button
              href='https://juullabs.com'
            >
              <TranslatedMessage id='country_selector.global.learn_about' />
            </Button>
            <Button
              href='/global'
              className='button--outline-soft'
            >
              <TranslatedMessage id='country_selector.global.see_all_websites' />
            </Button>
          </div>
        )
      }
    </div>
  )
}
