import Cookie from 'js-cookie';

export const decodeJsonCookieValue = function(cookieKey) {
  try {
    var value = Cookie.get(cookieKey);
    return value ? JSON.parse(value) : null;
  } catch (e) {
    return null;
  }
};
