export const DELIVERY_HINT_HAS_VIEWED = 'delivery_hint_has_viewed';
export const USER_DELIVERABILITY = 'user_deliverability';
export const AGE_GATE_COOKIE = 'hasVerifiedAgeGate';
export const CONTENTFUL_INTERACTION_COOKIE = 'contentful_interactions';
export const JUUL_ANONYMOUS_ID_COOKIE = 'juul_anonymous_id';
export const COUNTRY_SELECTOR_COOKIE = 'stay_on_this_store';
export const GDPR_COOKIE_KEY = 'hasAcknowledgedGdprSite';
export const COUNTRY_SELECTOR_GLOBAL = 'hasSeenCountrySelectorGlobal';
export const MEMBERSHIP_CONFLICT_TYPE = 'membershipConflictType';
export const AGE_VERIFICATION_COMPLETED_LEVEL = 'AVCompletedLevel';
