import mapKeys from 'lodash/mapKeys';
import mapValues from 'lodash/mapValues';
import getConsent from './consent';
import { getIterableLocale } from './utils';
import { decodeJsonCookieValue } from './utils/decodeJsonCookieValue';
import { gon } from './gon';
import { JUUL_ANONYMOUS_ID_COOKIE } from './constants/cookies';

const MARKETING_CONSENT = 'marketing';
const STATISTICS_CONSENT = 'statistics';
const NECESSARY_CONSENT = 'necessary';

// This is a mapping between Segment destinations and the
// Cookiebot consent category to which they belong. Using this mapping
// we can compute which destinations should be turned on/off based on
// the user's preferences.
const DESTINATION_CONSENT_CATEGORIES = {
  'Amazon S3': MARKETING_CONSENT,
  FullStory: NECESSARY_CONSENT,
  'Google Analytics': STATISTICS_CONSENT,
  'Google Tag Manager': NECESSARY_CONSENT,
  Iterable: NECESSARY_CONSENT,
  Salesforce: MARKETING_CONSENT,
  'Segment.io': NECESSARY_CONSENT,
  Webhooks: STATISTICS_CONSENT,
};

const getAnonymousId = () => decodeJsonCookieValue(JUUL_ANONYMOUS_ID_COOKIE);

const makeState = () => ({
  isReady: false,
  queue: [],
  runOnce: false,
});

// We need to explicitly memoize the state on the global object. Simply
// memoizing it using a local variable within this file is *not* sufficient.
// Standard behaviour is for an export to be computed when it is first
// imported, with all subsequent imports simply returning a reference to the
// initial computed value. Unfortunately there are some bits of the app where
// we re-compute the export and thus if we stored the state locally we'd have
// multiple copies of said state.
const getState = () => {
  if (!global._segmentState) global._segmentState = makeState();
  return global._segmentState;
};

// A factory method that builds wrappers for global.analytics methods. Pass
// in the name of the method (prop) we want to call on the underlying
// analytics object to create an analytics.js wrapper fn.
const factory = (prop, queueUntilLoaded = true) => (...args) => {
  const state = getState();

  // Send events to segment only when configured
  if (gon('env.SEGMENT_ENABLED') !== 'true') return;

  // If (for some unknown reason - but it does happen?!) analytics isn't
  // defined then gracefully abort.
  if (!global.analytics) return;

  // If we are not ready, then queue the analytics call by appending it
  // to an array. We must wait until analytics has loaded and the anonymousId
  // has been correctly set.
  if (!state.isReady && queueUntilLoaded) {
    state.queue.push({ args, prop });
    return;
  }

  // If we are ready, then simply pass the call onto analytics.
  global.analytics[prop](...args);
};

// A method that actually sends all previously queued/deferred analytics calls.
const processQueue = () => {
  const state = getState();
  for (const { prop, args } of state.queue) global.analytics[prop](...args);
  state.queue = []; // Clear the queue once we've processed all the calls.
};

// If the consent is falsey (null), it means we don't use cookiebot on this store and thus
// everything is allowed. Whitelist everything by setting All => true. If the consent is truthy,
// loop through every destination and explicitly whitelist it if exists within a permitted
// category.
const getIntegrations = consent => {
  const integrations = consent
    ? {
        All: false,
        ...mapValues(DESTINATION_CONSENT_CATEGORIES, v => !!consent[v]),
      }
    : { All: true, Iterable: undefined };
  if (gon('current_store.preferences.forget_iterable_users')) {
    const ageVerifiedForMarketing =
      gon('user.age_verified_for_marketing') || false;
    const permanentlyLocked = gon('user.permanently_locked') || false;
    integrations['Iterable'] =
      (integrations.All || integrations.Iterable) &&
      ageVerifiedForMarketing &&
      !permanentlyLocked;
  }

  return integrations;
};

const initialize = async (extraPageParams = {}) => {
  const state = getState();
  // If we've already run initialize once, make sure we don't run again by
  // aborting early.
  if (state.runOnce) return;
  state.runOnce = true;

  // If we can't find segment then we should just give up and return as theres not
  // much we can do.
  if (!global.analytics) return;

  // Wait for Cookiebot consent before proceeding. We need to know which integrations
  // the user has permitted us to load in so we can't proceed until we have it.
  const consent = await getConsent();
  const integrations = getIntegrations(consent);

  // Extract the userId from gon. If it doesn't exist then the user must be a guest
  // and according to Segment docs we shouldn't specify a user id.
  const userId = gon('user.segment_id') || null;

  // We use segment extensively on the back-end. The back-end SDK generates an anonymous ID
  // (although the front-end library does this for us, the back-end one doesn't). In order
  // to match back-end and front-end events to the same guest we must ensure the same
  // anonymous ID is used on the front-end. We can set it as soon as Segment is finished
  // loading - using the ready callback method.
  const anonId = getAnonymousId();
  global.analytics.ready(() => {
    global.analytics._user.anonymousId(anonId);
    // All calls to analytics that have happened before this point will have
    // been queued on our end - as we need to wait until we correctly set
    // the anonymousId. If we didn't queue these calls, they would go out
    // with Segment's auto-created anonymousId which would make it look like
    // we had extra visitors.
    // Update the state object to prevent future calls from being queued,
    // and then finally dispatch all queued calls.
    state.isReady = true;
    processQueue();
  });

  // Tell Segment to explicitly load in all our whitelisted integrations.
  global.analytics.load(gon('env.SEGMENT_WRITE_KEY'), { integrations });

  // Identify the user with some basic traits.
  const tz = gon('geo_location.time_zone');
  if (userId) {
    factory('identify')(userId, {
      email: gon('user.email') || '',
      geo_location: {
        ...gon('geo_location'),
        time_zone: { id: tz },
      },
      locale: getIterableLocale(),
    });
  }

  // Inform Segment about the page we're currently looking at.
  // We must ensure that we append the Cookiebot consent preferences
  // to the payload of the page request. If GTM is a destination,
  // this page call will generate an event in the "Data Layer" called
  // "Page Loaded", which will contain the aforementioned preferences.
  // These preferences can then be consumed by GTM triggers which fire in
  // response to the "Page Loaded" event - allowing us to conditionally serve
  // tags from GTM in adherence with a user's preferences.
  factory('page')(null, {
    ...extraPageParams,
    ...mapKeys(consent || {}, (v, k) => `${k}_preferences`),
  });
};

// eslint-disable-next-line import/no-default-export
export default {
  _getState: getState,
  _makeState: makeState,
  getAnonymousId,
  identify: factory('identify'),
  initialize,
  on: factory('on', false),
  page: factory('page') as typeof global.analytics.page,
  track: factory('track') as typeof global.analytics.track,
  trackLink: factory('trackLink') as typeof global.analytics.trackLink,
};
