import React, { MutableRefObject, ReactNode } from 'react';
import classNames from 'classnames';
import get from 'lodash/get';

import segment from '../segment';
import { Icon } from '@viewlio/juulio-bridge/src/common/components/Icon';

const DEFAULT_PROCESSING_TEXT_KEY = 'common.forms.buttons.pending';
const DEFAULT_PROCESSING_TEXT = get(global, [
  'translations',
  'messages',
  DEFAULT_PROCESSING_TEXT_KEY,
]);

type ForwardedRef<T> =
  | ((instance: T | null) => void)
  | MutableRefObject<T | null>
  | null;

export type Props = {
  analyticsEventData?: Record<string, any>;
  analyticsEventName?: string;
  className?: string;
  disabled?: boolean;
  /**
   * You can optionally manually set the element that you want this component
   * to use. Ignored if an `href` is set.
   */
  element?: React.ComponentType<any> | 'a' | 'button' | 'div';
  /** If an href is used then the `element` prop will be ignored and it will
   * default to using an anchor tag.
   */
  href?: string;
  onClick?(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
  processing?: boolean;
  /** Text to display while `processing` is set to `true` */
  processingText?: string | ReactNode;
  target?: string;
  /** Useful in some cases where we need to set `type="submit"` */
  type?: string;
  value?: string;
  segmentTrack?: (event: string, properties?: Record<string, any>) => void;
  forwardedRef?: ForwardedRef<unknown>;
  [prop: string]: any;
};

export const Button: React.FC<Props> = ({
  children,
  disabled = false,
  processing,
  processingText = DEFAULT_PROCESSING_TEXT,
  element = 'button',
  analyticsEventData,
  analyticsEventName,
  segmentTrack = segment.track,
  forwardedRef = null,
  ...props
}) => {
  const trackClick = () => {
    if (!analyticsEventName) {
      return;
    }

    segmentTrack(analyticsEventName, analyticsEventData);
  };

  const handleClick = e => {
    if (disabled) {
      // this naturally happens for button tags, but not for others tags/components
      e.preventDefault();
    } else {
      trackClick();
      props.onClick && props.onClick(e);
    }
  };

  // Since the default <button> element can't have an href, we can assume we want an anchor tag.
  const Element = props.href ? 'a' : element;

  let buttonText = (
    <span className="button__text">
      {children}
      <Icon name="button-arrow-white" path="icons" className="button__icon" />
    </span>
  );

  if (processing) {
    buttonText = <Processing text={processingText} />;
  }

  return (
    <Element
      {...props}
      ref={forwardedRef}
      disabled={disabled || processing}
      onClick={handleClick}
      className={classNames('button', props.className)}
    >
      {buttonText}
    </Element>
  );
};

const Processing = ({ text }) => (
  <div className="button__processing">
    <div className="Spinner button__processing__spinner" />
    {text && <div className="button__processing__text">{text}</div>}
  </div>
);

Button.displayName = 'Button';

// eslint-disable-next-line import/no-default-export
export default Button;
