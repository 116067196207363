// A function that returns a promise that resolves to the Cookiebot preferences.
const consent = () => {
  // Grab the Cookiebot if it exists in the window.
  const cookieBot = window.Cookiebot ? window.Cookiebot : null;

  // If the store doesn't use Cookiebot, then resolve instantly with null consent.
  if (!cookieBot) return Promise.resolve(null);

  // If the store does use Cookiebot, but we've already captured the users preferences,
  // again lets return instantly with the captured preferences.
  if (cookieBot.hasResponse) return Promise.resolve(cookieBot.consent);

  // Finally, if the store does use Cookiebot and we still haven't captured user preferences,
  // wait until we have and then return the captured preferences.
  return new Promise(rs => {
    window.addEventListener('CookiebotOnAccept', rs, { once: true });
    window.addEventListener('CookiebotOnDecline', rs, { once: true });
  }).then(() => cookieBot.consent);
};

export default consent;
